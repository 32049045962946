html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.main-nav > nav > ul {
  margin: 0;
  width: 100%;
  background-color: #ccc;
  padding: 0;
  overflow: hidden;
}

.main-nav > nav > ul li {
  box-sizing: border-box;
  display: block;
  width: 50%;
  float: left;
}

.main-nav > nav > ul li:first-child {
  border-right: 1px solid #fff;
}

.main-nav > nav > ul li > a {
  display: block;
  color: #000;
  text-decoration: none;
  padding: 1rem;
}

.main-nav > nav > ul li > a.active {
  background-color: #c33;
  color: #fff;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.col,
.col-50 {
  flex: 1;
  padding: 15px;
}

.row > .col:first-child {
  border-right: 1px solid #ccc;
  max-width: 280px;
}

.row > .col:last-child {
  background: #fafafa;
}

.row > .col-50:first-child {
  border-right: 1px solid #ccc;
  max-width: 50%;
}

.row > .col-50:first-child img {
  width: 100%;
}

.row > .col-50:last-child {
  background: #fafafa;
}

.row.full-left > .col:first-child,
.row.full-left > .col-50:first-child {
  max-width: 100%;
  border-right: none;
}

.row.full-left > .col:last-child,
.row.full-left > .col-50:last-child {
  display: none;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 3rem;
}

/* Responsive */
.responsive .react-colorful {
  width: auto;
}

#color-form {
  width: auto;
}

#color-form > input:first-child {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 90px;
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #eee;
  outline: none;
  font: inherit;
  text-transform: uppercase;
  text-align: center;
  height: 2rem;
  vertical-align: top;
}

#color-file-upload-wrapper {
  /*float: right;*/
  display: inline-block;
}

.file-upload-wrapper {
  display:  inline-block;
  width: 9rem;
  overflow: hidden;
  position: relative;
  height: 2rem;
}

.file-upload-wrapper > input {
  position: absolute;
  width: 100%;
  max-width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
}
.file-upload-wrapper > button {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #0B6AFF;
  border: none;
  color: #fff;
  border-radius: 4px;
}

.file-upload-wrapper > input:hover + button {
  background-color: #1B7AFF;
}

.faces-file-upload-wrapper {
  padding-top: 4rem;
  text-align: center;
}

.faces-file-upload-wrapper > .file-upload-wrapper {
  width: 36rem;
  max-width: 100%;
  height: 6rem;
}

.filters-header {
  margin-top: 0.9rem;
  border-bottom: 1px solid #ccc;
}

.filter-count {
  margin-left: 0.25rem;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
}

.overlay-body {
  position: absolute;
  left: 1rem;
  top: 1rem;
  bottom: 1rem;
  right: 1rem;
  padding: 1rem 2rem 1rem 1rem;
  overflow: auto;
  background-color: #fff;
  border-radius: 4px;
}

.overlay-close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  background-color: #333;
  border: none;
}

.overlay-close:hover {
  background-color: #111;
}

.overlay-close:before, .overlay-close:after {
  position: absolute;
  left: calc(1.5rem - 1px);
  top: calc(0.5rem - 0.5px);
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #fff;
}

.overlay-close:before {
  transform: rotate(45deg);
}

.overlay-close:after {
  transform: rotate(-45deg);
}

.overlay-body h2.more-header {
  border-top: 1px solid #ccc;
  padding-top: 1rem;
}

.more-wrapper h2 {
  white-space: unset !important;
}

.data-item {
  margin-bottom:  1rem;
}

.data-item > label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.card-title-ellipsis {
  white-space: unset !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
}

.result-colors-and-image {
  float: right;
}

.result-colors-and-image > div.button-image-search {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  cursor: default;
  margin-right: 8px;
  background-image: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='camera' class='svg-inline--fa fa-camera fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z'%3E%3C/path%3E%3C/svg%3E");
  opacity: 0.75;
}

.result-colors-and-image > div.color-button {
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid #aaa;
}

.selected-color {
  display: inline-block;
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border: 1px solid #aaa;
  vertical-align: top;
  margin-top: 2px;
  border-radius: 8px;
  margin-left: 4px;
}

.result-colors-and-image > div:not(last-child) {
  margin-right: 2px;
}

.selected-filter {
  border: none;
  background-color: #ddd;
  border-radius: 4px;
  padding: 0.5em 0.75em;
}

.selected-filter:not(:first-child) {
  margin-left: 4px;
}

.selected-filter:hover {
  background-color: #ccc;
}

.selected-filter > span {
  display: inline-block;
}

.selected-filter > span.value {
  margin-left: 4px;
}

.selected-filter > span.clear-icon {
  padding-left: 4px;
}

.clear-icon-small {
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: none;
}

.clear-icon-small:before, .clear-icon-small:after {
  position: absolute;
  left: 6pxem;
  top: 1px;
  content: ' ';
  height: 13px;
  width: 1px;
  background-color: #999;
}

.clear-icon-small:before {
  transform: rotate(45deg);
}

.clear-icon-small:after {
  transform: rotate(-45deg);
}


.selected-filter:hover span.label {
  text-decoration: line-through;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 12px;
}
.lds-facebook.inactive {
  display: none;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 0px;
  width: 4px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 0px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 6px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 12px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 0px;
    height: 16px;
  }
  50%, 100% {
    top: 4px;
    height: 8px;
  }
}

.detail-image {
  position: relative;
  display: block;
  padding: 0;
}

.detail-image > img {
  padding: 0;
  margin: 0;
}

img.detail-image {
  display: block;
  z-index: 1;
}

.bounding-boxes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.faces-wrapper,
.objects-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.detail-buttons {
  margin-bottom: 0.5rem;
}

.detail-buttons > button {
  background-color: #0B6AFF;
  border: none;
  color: #fff;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}

.detail-buttons > button.inactive {
  background-color: #999;
}

.detail-buttons > button.active {
  background-color: #292;
}

.detail-buttons > button:not(:first-child) {
  margin-left: 0.5rem;
}

.button-back {
  background-color: #0B6AFF;
  border: none;
  color: #fff;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.button-back:hover {
  background-color: #1B7AFF;
}

.detail-image.show-ocr > .bounding-boxes,
.detail-image.show-faces > .faces-wrapper,
.detail-image.show-objects > .objects-wrapper {
  display: block;
  opacity: 1;
}

.bounding-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 1px solid #fff;
  color: #fff;
  font-size: 0.9rem;
  box-sizing: border-box;
  padding: 0rem 0.15rem;
}

.faces-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  font-size: 0.9rem;
  box-sizing: border-box;
  padding: 0rem 0.15rem;
}

.faces-box.active {
  border: 2px solid #fc3;
}

.faces-box:hover {
  border-color: #0f0;
}

.objects-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  font-size: 0.9rem;
  box-sizing: border-box;
  padding: 0rem 0.15rem;
}

.faces-view {
  padding: 1rem;
}

.score-item {
  width: 100%;
  height: 6px;
  margin: 4px 0;
  padding: 1px;
  background-color: #eee;
}

.score-item > div {
  background-color: #99aaFF;
  width: 0;
  height: 100%;
}

.info-box {
	padding: 1em;
  background-color: #ddd;
  border-radius: 4px;
  text-align: center;
}

.info-box-warning {
  background-color: #fc0;
}

.info-box-error {
  background-color: #fcc;
  color: #900;
}

.tab-view {

}

.tabs {
  overflow: hidden;
}

.tab-container {
  padding: 1em;
  background-color: #ccc;
}

.tabs > div {
  float: left;
  background-color: #efefef;
  margin-right: 3px;
  padding: 0.5em 0.5em;
  border-bottom: 3px solid #fff;
  cursor: default;
}

.tabs > div.active {
  background-color: #ccc;
  border-bottom-color: #ccc;
}

.feature-image {
  max-height: 150px;
  max-width: 300px;
  margin-right: 1em;
}